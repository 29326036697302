@use '../util' as *;
@import '../util/breakpoints.scss';

.keyboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px;  // Set the gap to 6px
    padding: 20px;
    color:hsl(0, 0%, 97%);    
    
    .key {
      background-color: hsl(200, 1%, 51%);
      border: none;
      border-radius: 5px;
      width: 43px;  // Set the width to 35px
      height: 58px;  // Set the height to 58px
      font-size: 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      display: flex;
      justify-content: center;
        align-items: center;

      
      
      &:hover {
        background-color: #e0e0e0;
      }
      
      &.large {
        width: 70px;  // Adjust the width for large keys
      }
    }
    
    .space {
      flex-basis: 100%;
      height: 0;
    }
  }
 
  .key.large {
    svg {
      width: 30px;  
      height: 30px; 
    }
  }

 .key.correct{
    background-color: hsl(120, 100%, 28%);
    border-color: hsl(120, 100%, 28%);
}
.key.present{
    background-color: hsl(49, 51%, 47%);
    border-color: hsl(49, 51%, 47%);
}
.key.absent{
    background-color: hsl(0, 0%, 35%);
    border-color: hsl(0, 0%, 35%);
}

@include breakpoint-down('small'){
  body{
    font-size: 12px;
    padding: 0;
    h1{
      font-size: 1.9rem;
    }
  }
    .keyboard{
        padding: 0;
        .key{
            width: 8vw;
            height: 58px;
            font-size: 15px;
        }
        .key.large{
            width: 11vw;
            height: 58px;
            svg{
                width: 38px;
                height: 58px;
            }
        }
    }
}