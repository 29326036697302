@keyframes flip-in{
    from{
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(-90deg);
    }
}

@keyframes flip-out{
    from{
        transform: rotateX(-90deg);
    }
    to {
        transform: rotateX(0deg);
    }
}
@keyframes win {
    0% { transform: translateY(0); }
    25% { transform: translateY(-20px); }
    50% { transform: translateY(0); }
    75% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
}
@keyframes jiggle {
    0%, 100% {
      transform: translateX(0px);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-10px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(10px);
    }
  }
@keyframes pop {
    0% {
      transform: scale(1);
      border-color: rgba(0, 0, 0, 0.1);
    }
    50% {
      transform: scale(1.1);
      border-color: rgba(0, 0, 0, 0.5);
    }
    100% {
      transform: scale(1);
      border-color: rgba(0, 0, 0, 1);
    }
  }
.flip-in{
    animation: flip-in 0.5s ease-in-out;
}
.flip-out{
    animation: flip-out 0.5s ease-in-out;
}
.jiggle {
    animation: jiggle 0.5s ease-in-out ;
  }

.win {
    animation: win 1s ease-in-out;
}

.pop {
    animation: pop 0.5s ease-in-out forwards;
  }