@use '../util' as *;

html {
	font-size: 100%;
	font-family: var(--font-body);
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	margin: 0;
	padding: 1rem;
	background-color: hsl(240,3%,7%);
	color: hsl(0, 0%, 100%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-weight:600;

	.fa-refresh {
		margin-left: 10px; /* Adjust as needed */
		color: green;
	  }
	
}
