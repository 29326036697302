:root {
  --font-body:"Open Sans", Arial, sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
}

@keyframes flip-in {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(-90deg);
  }
}
@keyframes flip-out {
  from {
    transform: rotateX(-90deg);
  }
  to {
    transform: rotateX(0deg);
  }
}
@keyframes win {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes jiggle {
  0%, 100% {
    transform: translateX(0px);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}
@keyframes pop {
  0% {
    transform: scale(1);
    border-color: rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.1);
    border-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    transform: scale(1);
    border-color: rgb(0, 0, 0);
  }
}
.flip-in {
  animation: flip-in 0.5s ease-in-out;
}

.flip-out {
  animation: flip-out 0.5s ease-in-out;
}

.jiggle {
  animation: jiggle 0.5s ease-in-out;
}

.win {
  animation: win 1s ease-in-out;
}

.pop {
  animation: pop 0.5s ease-in-out forwards;
}

html {
  font-size: 100%;
  font-family: var(--font-body);
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 1rem;
  background-color: hsl(240, 3%, 7%);
  color: hsl(0, 0%, 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
}
body .fa-refresh {
  margin-left: 10px; /* Adjust as needed */
  color: green;
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 2.25rem;
  letter-spacing: 0.125rem;
  font-weight: 700;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

.guess {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.1875rem;
  width: 18.75rem;
  margin-bottom: 0.375rem;
}
.guess__tile {
  border: 0.125rem solid rgba(66, 66, 66, 0.503);
  height: 3.25rem;
  width: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  transition: border-color 0.3s ease;
}

.correct {
  background-color: hsl(120, 100%, 28%);
  border-color: hsl(120, 100%, 28%);
}

.present {
  background-color: hsl(49, 51%, 47%);
  border-color: hsl(49, 51%, 47%);
}

.absent {
  background-color: hsl(0, 0%, 35%);
  border-color: hsl(0, 0%, 35%);
}

.not_in_the_list {
  opacity: 0;
  position: absolute;
}
.not_in_the_list--visible {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3.125rem;
  width: 7.125rem;
  background-color: aliceblue;
  color: black;
  text-transform: none;
  border: 0.125rem solid oldlace;
  border-radius: 0.5rem;
  font-size: 0.625rem;
  font-weight: bold;
  z-index: 100;
}

.keyboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
  padding: 20px;
  color: hsl(0, 0%, 97%);
}
.keyboard .key {
  background-color: hsl(200, 1%, 51%);
  border: none;
  border-radius: 5px;
  width: 43px;
  height: 58px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.keyboard .key:hover {
  background-color: #e0e0e0;
}
.keyboard .key.large {
  width: 70px;
}
.keyboard .space {
  flex-basis: 100%;
  height: 0;
}

.key.large svg {
  width: 30px;
  height: 30px;
}

.key.correct {
  background-color: hsl(120, 100%, 28%);
  border-color: hsl(120, 100%, 28%);
}

.key.present {
  background-color: hsl(49, 51%, 47%);
  border-color: hsl(49, 51%, 47%);
}

.key.absent {
  background-color: hsl(0, 0%, 35%);
  border-color: hsl(0, 0%, 35%);
}

@media (max-width: 39.9375em) {
  body {
    font-size: 12px;
    padding: 0;
  }
  body h1 {
    font-size: 1.9rem;
  }
  .keyboard {
    padding: 0;
  }
  .keyboard .key {
    width: 8vw;
    height: 58px;
    font-size: 15px;
  }
  .keyboard .key.large {
    width: 11vw;
    height: 58px;
  }
  .keyboard .key.large svg {
    width: 38px;
    height: 58px;
  }
}